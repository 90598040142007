<template>

  <!-- <img src="https://image.flaticon.com/icons/png/512/60/60817.png" width="50" height="50" style="float:left" alt="" @click="$router.push('/home')" v-if="$route.path != '/login'"> -->
  <header id="usrp">
    <div class="header-logo-menu">
      <div id="nav-drawer">
        <input id="nav-input" type="checkbox" v-model="check" class="nav-unshown">
        <label id="nav-open" for="nav-input" style="padding: 4px 0px 0px 4px;"><span></span></label>
        <label class="nav-unshown" id="nav-close" for="nav-input"></label>
        <div id="nav-content">
          <table>
            <thead>
              <tr>
                <th>
                  リンク一覧
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td @click="check=false;change_page(0)">
                  案件一覧
                </td>
              </tr>
              <tr>
                <td @click="check=false;change_page(1)">
                  クライアント企業一覧
                </td>
              </tr>
              <tr>
                <td @click="check=false;change_page(2)">
                  請求表出力
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="menu_logout">
        <span class="operate-button" @click="logout" uk-icon="icon: sign-out; ratio:1.5;"></span>
      </div>
    </div>
  </header>

  <body>
    <home v-if="$store.state.page == 0"/>
    <cl v-else-if="$store.state.page ==1"/>
    <ou v-else-if="$store.state.page == 2"/>
  </body>

</template>

<script type="text/javascript">
  import home from "./home.vue"
  import cl from "./client_lists.vue"
  import ou from "./output_billing.vue"
  export default {
    components: {
      home,
      cl,
      ou
    },
    data() {
      return {
        check: false,
        status:false
      }
    },
    mounted(){
      
    },
    created(){
      this.status = this.$store.state.login_status_i
      this.checkLogined()
    },
    methods:{
      change_page(page_num){
        this.$store.commit("change_page",{page_num:page_num})
      },
      checkLogined() {
        if (!this.status) {
          this.$router.push("/intelligent-login")
        }
      },
      logout(){
        this.$store.commit("setLogout_i", {})
        sessionStorage.removeItem("email_i")
        sessionStorage.removeItem("pass_i")
      }
    },
    watch: {
      '$store.state.login_status_i'(newVal, oldVal) { //eslint-disable-line
        if (newVal == false) {
          this.$router.push("/intelligent-login")
        }
      }
    }
  }
</script>

<style scoped>
  table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
  }

  table tr {
    border-bottom: solid 1px #eee;
    cursor: pointer;
    text-align: center;
  }

  table tbody tr:hover {
    background-color: #d4f0fd;
  }

  header {
    padding: 10px;
    background: #ccc;
  }

  #nav-drawer {
    position: relative;
  }

  /*チェックボックス等は非表示に*/
  .nav-unshown {
    display: none;
  }

  /*アイコンのスペース*/
  #nav-open {
    display: inline-block;
    width: 30px;
    height: 22px;
    vertical-align: middle;
  }

  /*ハンバーガーの形をCSSで表現*/
  #nav-open span,
  #nav-open span:before,
  #nav-open span:after {
    position: absolute;
    height: 3px;
    /*線の太さ*/
    width: 25px;
    /*長さ*/
    border-radius: 3px;
    background: #555;
    display: block;
    content: '';
    cursor: pointer;
  }

  #nav-open span:before {
    bottom: -8px;
  }

  #nav-open span:after {
    bottom: -16px;
  }

  /*閉じる用の薄黒箇所*/
  #nav-close {
    display: none;
    position: fixed;
    z-index: 99;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: black;
    opacity: 0;
    transition: .3s ease-in-out;
  }

  /*メニューの中身*/
  #nav-content {
    overflow: auto;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    width: 90%;
    max-width: 330px;
    /*最大幅（お好みで調整を）*/
    height: 100%;
    background: #fff;
    transition: .3s ease-in-out;
    -webkit-transform: translateX(-105%);
    transform: translateX(-105%);
  }

  /*チェックがついたら表示させる*/
  #nav-input:checked~#nav-close {
    display: block;
    opacity: .5;
  }

  #nav-input:checked~#nav-content {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    box-shadow: 6px 0 25px rgba(0, 0, 0, .15);
  }

  .header-logo-menu {
    display: flex;
    display: -moz-flex;
    display: -o-flex;
    display: -webkit-flex;
    display: -ms-flex;
    flex-direction: row;
    -moz-flex-direction: row;
    -o-flex-direction: row;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
  }

  /*ロゴやサイトタイトルをセンタリング*/
  .logo-area {
    text-align: center;
    margin: auto;
  }

  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
  }

  #nav {
    padding: 30px;
  }

  #nav a {
    font-weight: bold;
    color: #2c3e50;
  }

  #nav a.router-link-exact-active {
    color: #42b983;
  }
</style>
