<template id="">
 <div>
  <div class="admin">
    <h3><img class="logo" src="@/img/logo.png" alt="インテリジェントヘルスケア ストレスチェックApp"></h3>
    <h3>請求表出力</h3>
    <div v-show="!$store.state.common.sIsLoading && !$store.state.common.sDataLoading">
    <div class="tag-group">
    </div>
    <div id="data-area" v-cloak>
      <select class="" name="year" v-model="select_year">
          <option v-for="n in now_year - min_year + 1" :key="n" :value="now_year - n + 1">{{now_year - n + 1}}</option>
      </select>年
      <select class="" name="year" v-model="select_month">
          <option v-for="n in 12" :key="n" :value="n" >{{n}}</option>
      </select>月実施分
    </div>
    <br><br>
    <div class="uk-width-1-1">
      <div class="uk-margin">
        <button type="button" name="button" class="uk-button uk-button-secondary uk-width-1-2" @click="get_billing">請求表出力</button>
      </div>
    </div>
    <button type="button" name="button" class="uk-button uk-button-secondary uk-width-1-2" @click="change_page(0)">戻る</button>
  </div>
</div>
</div>
</template>

<script>
  export default {
    data() {
      return {
        status: null,
        min_date: null,
        min_year: null,
        min_month: null,
        now_date: null,
        now_year:null,
        now_month:null,
        select_year:null,
        select_month:null
      }
    },
    mounted(){
      this.$store.state.common.sIsLoading = false;
      document.title = "請求表出力"
    },
    created(){
      this.$store.state.common.sIsLoading = true;
      this.$store.state.common.sDataLoading = true;
      this.status = this.$store.state.login_status_i
      this.checkLogined()
      if (this.status) {
        this.min_date = new Date("2020-01-01 00:00:00") //2020年1月1日0時0分0秒を最低値としておく
        this.min_year = this.min_date.getFullYear()
        this.min_month = this.min_date.getMonth() //

        this.now_date = new Date()       //現時点までの日付が入力できれば良いので
        this.now_year = this.now_date.getFullYear()
        this.now_month = this.now_date.getMonth() //先月分を見れた方が便利なので+1 せずこのまま使う

        this.select_year = this.now_year
        this.select_month = this.now_month
      }
      this.$store.state.common.sDataLoading = false;
    },
    methods: {
      isSelect(num) {
        this.isActive = num
      },
      change_page(page_num){
        this.$store.commit("change_page",{page_num:page_num})
      },
      checkLogined() {
        if (!this.status) {
          this.$router.push("/intelligent-login")
        }
      },
      get_billing(){
        if(this.select_month == 0){
          window.alert("出力する月を再選択してください")
          return
        }
        console.log(this.select_month)
        this.$axios({
          method:"POST",
          url:"/intelligent/get_billing",
          data:{
            year:this.select_year,
            month:this.select_month
          },
          responseType: 'blob',
          dataType: "binary"
        }).then((response)=>{
          console.log(response)
          if(response.headers["content-type"] == "None"){
            window.alert("対象月の実施データはありません。")
            return
          }
          this.downloadCsvBlob(response.data , this.select_year+"年" + this.select_month+"月" +"_請求表.xlsx")
        }).catch((error)=>{
          console.log(error)
        })
      },
      downloadCsvBlob(blob, fileName) {
        if (window.navigator.msSaveOrOpenBlob) {
          // for IE,Edge
          window.navigator.msSaveOrOpenBlob(blob, fileName);
        } else {
          // for chrome, firefox
          const url = URL.createObjectURL(new Blob([blob], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,"
          }));
          const linkEl = document.createElement('a');
          linkEl.href = url;
          linkEl.setAttribute('download', fileName);
          document.body.appendChild(linkEl);
          linkEl.click();

          URL.revokeObjectURL(url);
          linkEl.parentNode.removeChild(linkEl);
        }

      },
      make_blob(base64_str) {
        let dataURI = "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," + base64_str;
        // "iVBORw..."をバイナリに変換
        var byteString = atob(dataURI.split(",")[1]);

        // "image/png"
        var mimeType = dataURI.match(/(:)([a-z/]+)(;)/)[2];

        // バイナリからBlobを作成
        for (var i = 0, l = byteString.length, content = new Uint8Array(l); l > i; i++) {
          content[i] = byteString.charCodeAt(i);
        }

        var blob = new Blob([content], {
          type: mimeType,
        });
        return blob
        // window.open(window.URL.createObjectURL( blob )) ;
      }
  }
  }
</script>

<style scoped>
  div {
    display: inline-block;
    width: 100%;
  }

  #data-area {
    max-height: 80vh;
    overflow-y: auto;
  }

  .tag-group {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    zoom: 1;
    /* Fix for IE7 */
    *display: inline;
    /* Fix for IE7 */

  }

  ul {
    list-style: none;
    text-align: center;
  }

  .tab-group li {
    border: 1px solid #428bca;
    border-radius: 4px;
    position: relative;
    float: left;
    padding: 10px 15px 10px 15px;
  }

  .tab-group li:hover {
    background-color: #d4f0fd;
    cursor: pointer;
  }

  .active {
    background-color: #428bca;
    color: #fff;
  }

  .tab-group li+li {
    margin-left: -1px;
  }

  .tab-group>li:not(:first-child):not(:last-child) {
    border-radius: 0;
  }

  .tab-group>li:first-child {
    margin-left: 0;
  }

  .tab-group>li:first-child:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .tab-group>li:last-child:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  [v-cloak] {
    display: none;
    cursor: wait;
  }
</style>
